import React from "react";
import { graphql, Link } from "gatsby";
import { GraphQLResponse } from "../models";
import { getFrontMatter } from "../util";
import ProjectGrid from "../components/projects/ProjectGrid";
import { Layout } from "../layouts";
import { Container } from "../components/core/Container/styled";
import {
  ProjectGridTitle,
  ProjectGridViewMore,
} from "../components/projects/ProjectGrid/styled";
import { fade } from "../transition";
import Banner from "../components/modules/Banner";
import { Color } from "../settings";
import Intro from "../components/core/Intro";
import { FocalPointType } from "../types";

interface IndexProps {
  title: string;
  banner: {
    image: string;
    focalPoint: FocalPointType;
    videoId?: string;
  };
}

interface IndexPageProps extends GraphQLResponse<IndexProps> {}

const IndexPage = (props: IndexPageProps) => {
  const { title, banner } = getFrontMatter(props);
  return (
    <Layout title={title}>
      <Banner
        image={banner.image}
        focalPoint={banner.focalPoint}
        videoId={banner.videoId}
        altText={title}
        backgroundColor={Color.concrete}
      />
      <Intro title={title} />
      <Container style={{ textAlign: "center" }}>
        <ProjectGridTitle>Recent work</ProjectGridTitle>
        <ProjectGrid
          isFilterable={false}
          projects={props.data.allMarkdownRemark.edges}
        />
        <ProjectGridViewMore
          to="/projects"
          exit={{
            length: 0.25,
            trigger: ({ exit, node }) => fade({ exit, node, direction: "out" }),
          }}
          entry={{
            length: 0.25,
            trigger: ({ exit, node }) => fade({ exit, node, direction: "in" }),
            delay: 0.25,
          }}
        >
          View all projects
        </ProjectGridViewMore>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "index" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              banner {
                videoId
                image
                focalPoint
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { eq: "project" } } }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            location
            thumbnail
          }
        }
      }
    }
  }
`;

export default IndexPage;
